import { uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const AutocompleteStyles = {
  root: {
    width: uiConfig.AMfull,
    ".MuiFormControl-root": {
      borderBottom: uiConfig.AM0,
    },
    ".MuiOutlinedInput-root": {
      padding: `${uiConfig.AM0} !important`,
    },
    ".MuiSvgIcon-root": {
      color: colors.autoCompleteSvg,
    },
  },
};
