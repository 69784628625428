import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { AMButton, AMLineProgress } from "components/common";
import * as colors from "designsystem/Palette";
import { useTranslation } from "react-i18next";
import { MoreHoriz } from "@mui/icons-material";
import { sortInfoBoxes } from "utils/CommonFunctions";
import AMCommonInfoCard from "./AM_CommonInfoCard";
import { useNavigate } from "react-router-dom";
import ROUTES from "utils/CommonRouter";
import { textStyles } from "designsystem";

// interface SubTitle {
//   label: string;
//   type: string;
//   value: string;
// }

interface InfoItem {
  label: string;
  type: string;
  value: string;
  orderCount: number;
}

// interface Data {
//   route_type?: string;
//   subTitle: SubTitle;
//   infoArea: InfoItem[];
//   summaryText: SubTitle;
// }

interface AMInfoCardProps {
  title: string;
  data: any;
  buttonValue?: string;
  fromOffer?: boolean;
}

const AMInfoCard: React.FC<AMInfoCardProps> = ({
  title,
  data,
  buttonValue,
  fromOffer,
}) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  const routeForLearnMore = (buttonRouteLink: string, rowData: any) => {
    navigate(ROUTES[buttonRouteLink], {
      state: {
        data: rowData,
        fromOffer: true
      },
    });
  };

  const sortedInfoArea = fromOffer
    ? data?.mediaChannels?.slice().sort(sortInfoBoxes)
    : data?.infoArea?.slice().sort(sortInfoBoxes);

  return (
    <section>
      {fromOffer ? (
        <AMCommonInfoCard
          isLgLabel
          key={title}
          icon={<MoreHoriz />}
          title={title}
          description={
            <div>
              <CardContent
                sx={{
                  minHeight: "48px",
                }}
                key={title}
              >
                <Typography variant="h5">
                  {data?.totalAudienceAsString}
                  {" : " + data?.totalAudience}
                </Typography>
              </CardContent>
              <Card
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  padding: "16px 0",
                  background: colors.primaryContrastColor,
                }}
              >
                {sortedInfoArea?.map((item: any) => (
                  <CardContent
                    sx={{ paddingBottom: "0", paddingTop: "0" }}
                    key={item?.orderCount}
                  >
                    <Typography variant="h5">
                      {item?.mcName}
                      {" : " + item?.audiencePercentage}
                    </Typography>
                  </CardContent>
                ))}
              </Card>
              <CardContent
                sx={{
                  padding: "10px!important",
                }}
                key={data?.route_type}
              >
                <Typography
                  variant="h5"
                  sx={{ borderBottom: "1px solid #eee", paddingBottom: "5px" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: colors.primaryDarkColor,
                      fontWeight: textStyles.fontWeight.strong,
                    }}
                  >
                    {data?.totalAudienceAsString}
                  </Typography>
                  {" : " + data?.totalAudience}
                </Typography>
                <AMButton
                  center={true}
                  buttonClick={() =>
                    routeForLearnMore(data?.route_type ?? "/", data)
                  }
                  value={buttonValue ?? t("learnMore")}
                />
              </CardContent>
            </div>
          }
        ></AMCommonInfoCard>
      ) : (
        <AMCommonInfoCard
          isLgLabel
          key={title}
          icon={<MoreHoriz />}
          title={title}
          description={
            <div>
              <CardContent
                sx={{
                  minHeight: "48px",
                }}
                key={title}
              >
                <Typography variant="h5">
                  {data?.subTitle?.label}
                  {data?.subTitle?.type !== "KEY_VALUE"
                    ? ""
                    : " : " + data?.subTitle?.value}
                </Typography>
                {data?.subTitle?.type !== "KEY_VALUE" && (
                  <AMLineProgress value={parseInt(data?.subTitle?.value)} />
                )}
              </CardContent>
              <Card
                elevation={0}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  padding: "16px 0",
                  background: colors.primaryContrastColor,
                }}
              >
                {sortedInfoArea.map((item: InfoItem) => (
                  <CardContent
                    sx={{ paddingBottom: "0", paddingTop: "0" }}
                    key={item?.orderCount}
                  >
                    <Typography variant="h5">
                      {item.label}
                      {item?.type === "KEY_VALUE"
                        ? " : " + parseInt(item?.value) + "%"
                        : ""}
                    </Typography>
                    {item?.type !== "KEY_VALUE" && (
                      <AMLineProgress value={parseInt(item?.value)} />
                    )}
                  </CardContent>
                ))}
              </Card>
              <CardContent
                sx={{
                  padding: "10px!important",
                }}
                key={data?.route_type}
              >
                <Typography
                  variant="h5"
                  sx={{ borderBottom: "1px solid #eee", paddingBottom: "5px" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: colors.primaryDarkColor,
                      fontWeight: textStyles.fontWeight.strong,
                    }}
                  >
                    {data?.summaryText?.label}
                  </Typography>
                  {data?.summaryText?.type !== "KEY_VALUE"
                    ? ""
                    : " : " + data?.summaryText?.value}
                </Typography>
                <AMButton
                  center={true}
                  buttonClick={() =>
                    routeForLearnMore(data?.route_type ?? "/", data)
                  }
                  value={buttonValue ?? t("learnMore")}
                />
              </CardContent>
            </div>
          }
        ></AMCommonInfoCard>
      )}
    </section>
  );
};

export default AMInfoCard;
