import React from "react";
import { Button, ButtonProps as MuiButtonProps, useTheme } from "@mui/material";
interface ButtonProps extends Omit<MuiButtonProps, "color" | "onClick"> {
  value: string;
  filColor?: string;
  variant?: "text" | "outlined" | "contained";
}

const AMStatusButton = ({
  value,
  filColor,
  variant,
  ...props
}: Readonly<ButtonProps>) => {
  let bgcolor;
  let color;
  const theme = useTheme();

  if (
    filColor === "rejected" ||
    filColor === "Rejected" ||
    filColor === "UNASSIGNED_CAMPAIGN_STATUS_TYPE" ||
    filColor === "TO_DO_USER_CONTACT_VERIFICATION_TYPE" ||
    filColor === "FAILD_USER_CONTACT_VERIFICATION_TYPE" ||
    filColor === "FAILED_PAYMENT_STATUS_TYPE" ||
    filColor === "CLARIFICATION_REQUIRED_PAYMENT_STATUS_TYPE" ||
    filColor === "FAILED_USER_MC_PROFILE_VERIFICATION_TYPE" ||
    filColor === "TO_DO_USER_MC_PROFILE_VERIFICATION_TYPE"
  ) {
    bgcolor = "rgba(251, 233, 231, 0.8)!important";
    color = "rgb(216, 67, 21)";
  } else if (
    filColor === "pending" ||
    filColor === "Partially Filled" ||
    filColor === "REQUEST_SENT_USER_CONTACT_VERIFICATION_TYPE" ||
    filColor === "OPEN_PAYMENT_STATUS_TYPE" ||
    filColor === "REQUEST_SENT_USER_MC_PROFILE_VERIFICATION_TYPE"
  ) {
    bgcolor = "rgb(255, 248, 225)!important";
    color = "rgb(255, 193, 7)";
  } else if (
    filColor === "open" ||
    filColor === "Open" ||
    filColor === "IN_PROGRESS_USER_CONTACT_VERIFICATION_TYPE" ||
    filColor === "IN_PROGRESS_PAYMENT_STATUS_TYPE" ||
    filColor === "IN_PROGRESS_USER_MC_PROFILE_VERIFICATION_TYPE"
  ) {
    bgcolor = "rgb(227, 242, 253)!important";
    color = "rgb(33, 150, 243)";
  } else if (
    filColor === "filled" ||
    filColor === "Filled" ||
    filColor === "DONE_USER_CONTACT_VERIFICATION_TYPE" ||
    filColor === "DONE_PAYMENT_STATUS_TYPE" ||
    filColor === "DONE_USER_MC_PROFILE_VERIFICATION_TYPE" ||
    filColor === "ASSIGNED_CAMPAIGN_STATUS_TYPE"
  ) {
    bgcolor = "rgba(185, 246, 202, 0.6)!important";
    color = "rgb(0, 200, 83)";
  }
  const commonStyle = {
    bgcolor: bgcolor,
    color: color,
    typography: "button",
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
    },
  };

  return (
    <Button
      disableFocusRipple
      disableTouchRipple
      size="small"
      className="status-button"
      aria-label={value}
      aria-labelledby={value}
      variant={variant}
      sx={commonStyle}
      {...props}
    >
      {value}
    </Button>
  );
};
export default AMStatusButton;
