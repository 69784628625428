import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const PopoverStyles = {
  root: {
    "& .MuiMenuItem-root": {
      fontSize: textStyles.fontSize.large,
      color: `${colors.inputColor} !important`,
      fontWeight: textStyles.fontWeight.strong,
    },
    "&.popover-wrp": {
      ".MuiPaper-root": {
        fontFamily: textStyles.fontFamily.defaultFont,
      },
      ".filter-popup-wrp ": {
        minWidth: "400px",
        ".MuiButtonBase-root.MuiAccordionSummary-root": {
          background: `${colors.menuBgColor} !important`,
        },
      },
    },
    "&.popover-wrp.userCount": {
      ".MuiPaper-root": {
        padding: uiConfig.AM10,
        boxShadow: uiConfig.AMBreadcrumbsShadow,
        borderRadius: uiConfig.AM15,
        width: "250px",
        maxHeight: "200px",
        ".MuiList-root": {
          padding: 0,
          ".MuiListItem-root": {
            padding: "2px 10px",
            "&:hover": {
              background: colors.secondaryDarkColor,
              borderRadius: "8px",
              color: colors.primaryDarkColor,
              cursor: "pointer",
            },
          },
        },
      },
    },
  },
};
