import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

interface AMCheckBoxProps {
  label: string;
  onClick?: () => void;
}

const AMCheckBox = ({ label, onClick }: Readonly<AMCheckBoxProps>) => {
  return (
    <FormControlLabel
      className="common-checkbox"
      control={<Checkbox onChange={onClick} />}
      label={label}
    />
  );
};
export default AMCheckBox;
