import React, { lazy } from "react";
import AuthGuard from "components/auth/authGuard";
import TableForm from "components/page/TableForm/Form";
import ROUTES from "utils/CommonRouter";

const Contacts = lazy(() => import("views/main/MyAdvertmingo/Contacts"));
const Profile = lazy(() => import("views/main/MyAdvertmingo/Profile"));
const Dashboard = lazy(() => import("views/main/MyAdvertmingo/Dashboard"));
const Loader = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/Loader")
);
const Alert = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/Alert")
);
const Cards = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/Cards")
);
const Tabs = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/Tabs")
);
const List = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/List")
);
const Chip = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/Chip")
);
const Drawer = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/Drawer")
);
const Accordion = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/Accordion")
);
const Table = lazy(() => import("views/main/Table"));
const EmailForm = lazy(
  () => import("views/main/MyAdvertmingo/Contacts/Form/emailForm")
);
const AddressForm = lazy(
  () => import("views/main/MyAdvertmingo/Contacts/Form/addressForm")
);
const PhoneForm = lazy(
  () => import("views/main/MyAdvertmingo/Contacts/Form/phoneForm")
);
const FaxForm = lazy(
  () => import("views/main/MyAdvertmingo/Contacts/Form/faxForm")
);
const SocialMediaForm = lazy(
  () => import("views/main/MyAdvertmingo/Contacts/Form/socialMediaForm")
);
const BankDetails = lazy(() => import("views/main/MyAdvertmingo/BankDetails"));
const BankForm = lazy(
  () => import("views/main/MyAdvertmingo/BankDetails/Form")
);
const ViewBankDetails = lazy(
  () => import("views/main/MyAdvertmingo/BankDetails/ViewPage")
);
const Payments = lazy(() => import("views/main/MyAdvertmingo/Payments"));
const PaymentDetails = lazy(
  () => import("views/main/MyAdvertmingo/Payments/PaymentDetails")
);
const OrderDetails = lazy(
  () => import("views/main/MyAdvertmingo/Payments/OrderDetails")
);
const PaymentInvoice = lazy(
  () => import("views/main/MyAdvertmingo/Payments/PaymentInvoice")
);
const PaymentContentPostDetails = lazy(
  () => import("views/main/MyAdvertmingo/Payments/ContentPostDetails")
);
const AMComingsoon = lazy(() => import("components/page/AM_Comingsoon"));

const MediaChannels = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannels")
);
const AddMediaChannels = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannels/NewMediaChannel")
);
const Assessment = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannels/Assessment")
);
const EditSocialMedia = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannels/EditMediaChannel")
);
const AboutForm = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannels/EditMediaChannel/Form")
);
const Progress = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/Progress")
);
const Maintenance = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/Maintenance")
);
const FormFields = lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/FormFields")
);
const UnderConstructionComponent = lazy(
  () => import("views/main/UnderConstruction")
);
const MediaChannelsV2 = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannelsV2")
);
const AddMediaChannelsV2 = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannelsV2/NewMediaChannel")
);
const Analytics = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannels/Analytics")
);
const AdvertiserClients = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/AdvertiserClients")
);
const AddAdvertiserClients = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/AdvertiserClients/Form")
);
const AddClientEmail = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/AdvertiserClients/Form/ClientContact/emailForm"
    )
);
const AddClientPhone = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/AdvertiserClients/Form/ClientContact/phoneForm"
    )
);
const AddClientAddress = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/AdvertiserClients/Form/ClientContact/addressForm"
    )
);
const AdvertiserProjects = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/AdvertiserProjects")
);
const AddAdvertiserProjects = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/AdvertiserProjects/Form")
);
const ViewAdvertiserProjects = React.lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/AdvertiserProjects/View")
);
const AddAdvertiserMediaChannels = React.lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/AdvertiserProjects/Form/Step2/AdvertiserChannels/Form"
    )
);

const AdvertiserSocialMediaForm = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/AdvertiserClients/Form/ClientContact/socialMediaForm"
    )
);
const AdvertiserFaxForm = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/AdvertiserClients/Form/ClientContact/faxForm"
    )
);
const AddAudienceInterest = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/AdvertiserProjects/Form/Step2/AudienceInterests/Form"
    )
);
const ViewAdvertiserClients = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/AdvertiserClients/View")
);
const Tasks = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannels/Tasks")
);
const ViewTask = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannels/Tasks/ViewTask")
);

const Offers = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannels/Offers")
);
const ViewOffer = lazy(
  () => import("views/main/MyAdvertmingo/MediaChannels/Offers/ViewOffer")
);

const AdvertiserTasks = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/Tasks/index")
);

const AdvertiserOffers = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/Offers/index")
);

const PublishingOffers = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/PublishingOffers")
);

const NewPublishingOffers = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/PublishingOffers/NewPublishingOffers"
    )
);
const AddCustomOffer = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/PublishingOffers/NewPublishingOffers/SelectOffers/AddCustomOffer"
    )
);
const AdvertisementContent = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/AdvertisementContent")
);
const AdvertisementNewContentForm = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/AdvertisementContent/NewContent"
    )
);

const AdvertisementContentView = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/AdvertisementContent/ViewContent"
    )
);
const AdvertisementEditContentForm = lazy(
  () =>
    import(
      "views/main/MyAdvertmingo/Advertiser/AdvertisementContent/EditContent"
    )
);
const Campaigns = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/Campaigns")
);

const AddCampaigns = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/Campaigns/Form")
);
const AdvertiserPublications = lazy(
  () => import("views/main/MyAdvertmingo/Advertiser/AdvertiserPublications")
);
const AdvertiserNewPublication = lazy(
  () =>
    import("views/main/MyAdvertmingo/Advertiser/AdvertiserPublications/Form")
);

export const userRoutes = [
  {
    path: ROUTES["MY_ADVERT_ROUTE"],
    title: "myAdvert",
    element: <Dashboard />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["PROFILE"],
    title: "profile",
    element: <Profile />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACTS"],
    title: "contacts",
    element: <Contacts />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["MENU_TWO"],
    title: "menuTwo",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["MENU_THREE"],
    title: "menuThree",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["MENU_FOUR"],
    title: "menuFour",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["SECURITY"],
    title: "security",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BILLING_PAYMENTS"],
    title: "billing",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["MENU_SIX"],
    title: "menuSix",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["SUBMENU_TWO"],
    title: "subMenuTwo",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["SUBMENU_THREE"],
    title: "subMenuThree",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["SUBMENU_FOUR"],
    title: "subMenuFour",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["SUBMENU_FIVE"],
    title: "subMenuFive",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["SUBMENU_SIX"],
    title: "subMenuSix",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["SUB_SUBMENU_ONE"],
    title: "subSubMenuOne",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["SUB_SUBMENU_TWO"],
    title: "subSubMenuTwo",
    element: <AMComingsoon />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["TABLE"],
    title: "table",
    element: <Table />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["TABLE_EDIT_RECORD"],
    title: "editRecord",
    element: <TableForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["TABLE_CREATE_RECORD"],
    title: "addRecord",
    element: <TableForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_ACCORDION"],
    title: "accordion",
    element: <Accordion />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_CHIP"],
    title: "button",
    element: <Chip />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_DRAWER"],
    title: "drawer",
    element: <Drawer />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_LIST"],
    title: "list",
    element: <List />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_TABS"],
    title: "tabs",
    element: <Tabs />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_CARDS"],
    title: "cards",
    element: <Cards />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_ALERT"],
    title: "alert",
    element: <Alert />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_LOADER"],
    title: "loader",
    element: <Loader />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_PROGRESS"],
    title: "progress",
    element: <Progress />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_MAINTENANCE"],
    title: "maintenance",
    element: <Maintenance />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_UNDER_CONSTRUCTION"],
    title: "underConstruction",
    element: <UnderConstructionComponent />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BASIC_COMPONENTS_FORMFIELDS"],
    title: "formFields",
    element: <FormFields />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACT_EDIT_EMAIL"],
    title: "editEmail",
    element: <EmailForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACT_ADD_EMAIL"],
    title: "addEmail",
    element: <EmailForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACT_EDIT_ADDRESS"],
    title: "editAddress",
    element: <AddressForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACT_ADD_ADDRESS"],
    title: "addAddress",
    element: <AddressForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACT_EDIT_PHONE"],
    title: "editPhone",
    element: <PhoneForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACT_ADD_PHONE"],
    title: "addPhone",
    element: <PhoneForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACT_EDIT_FAX"],
    title: "editFax",
    element: <FaxForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACT_ADD_FAX"],
    title: "addFax",
    element: <FaxForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACT_EDIT_SOCIAL_MEDIA"],
    title: "editSocialMedia",
    element: <SocialMediaForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["CONTACT_ADD_SOCIAL_MEDIA"],
    title: "addSocialMedia",
    element: <SocialMediaForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BANK_DETAILS"],
    title: "bankDetails",
    element: <BankDetails />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BANK_DETAILS_EDIT_BANK"],
    title: "editBank",
    element: <BankForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BANK_DETAILS_ADD_BANK"],
    title: "addBank",
    element: <BankForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["BANK_DETAILS_VIEW"],
    title: "viewBank",
    element: <ViewBankDetails />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["PAYMENTS"],
    title: "payments",
    element: <Payments />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["PAYMENTS_DETAILS"],
    title: "paymentDetails",
    element: <PaymentDetails />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ORDER_DETAILS"],
    title: "orderDetails",
    element: <OrderDetails />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["PAYMENT_VIEW_INVOICE"],
    title: "invoiceDetails",
    element: <PaymentInvoice />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["PAYMENT_VIEW_CONTENT_POST"],
    title: "contentPostDetails",
    element: <PaymentContentPostDetails />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["MY_MEDIA_CHANNELS"],
    title: "myMediaChannels",
    element: <MediaChannels />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["MEDIA_CHANNELS"],
    title: "mediaChannels",
    element: <MediaChannelsV2 />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADD_MEDIA_CHANNELS"],
    title: "addMediaChannels",
    element: <AddMediaChannelsV2 />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["EDIT_MEDIA_CHANNELS"],
    title: "editMediaChannels",
    element: <AddMediaChannels />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["MEDIA_CHANNELS_ASSESSMENT"],
    title: "MediaChannelAssessment",
    element: <Assessment />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["EDIT_SOCIAL_MEDIA_CHANNELS"],
    title: "editSocialMediaChannels",
    element: <EditSocialMedia />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["MEDIA_CHANNELSV2"],
    title: "mediaChannelsV2",
    element: <MediaChannels />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADD_MEDIA_CHANNELSV2"],
    title: "addMediaChannels",
    element: <AddMediaChannels />,
    guard: AuthGuard,
  },
  { path: ROUTES["ADD_ABOUT"], element: <AboutForm />, guard: AuthGuard },
  { path: ROUTES["EDIT_ABOUT"], element: <AboutForm />, guard: AuthGuard },
  {
    path: ROUTES["ANALYTICS"],
    title: "analytics",
    element: <Analytics />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISING_CLIENTS"],
    title: "advertisingClients",
    element: <AdvertiserClients />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADD_ADVERTISING_CLIENTS"],
    title: "addAdvertiserClients",
    element: <AddAdvertiserClients />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["EDIT_ADVERTISING_CLIENTS"],
    title: "editAdvertiserClients",
    element: <AddAdvertiserClients />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADD_CLIENTS_EMAIL"],
    title: "addClientsEmail",
    element: <AddClientEmail />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADD_CLIENTS_PHONE"],
    title: "addClientsPhone",
    element: <AddClientPhone />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADD_CLIENTS_ADDRESS"],
    title: "addClientsAddress",
    element: <AddClientAddress />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["EDIT_CLIENTS_EMAIL"],
    title: "editClientsEmail",
    element: <AddClientEmail />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["EDIT_CLIENTS_PHONE"],
    title: "editClientsPhone",
    element: <AddClientPhone />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["EDIT_CLIENTS_ADDRESS"],
    title: "editClientsAddress",
    element: <AddClientAddress />,
    guard: AuthGuard,
  },

  {
    path: ROUTES["ADD_CLIENTS_SOCIAL_MEDIA"],
    title: "addSocialMedia",
    element: <AdvertiserSocialMediaForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["EDIT_CLIENTS_SOCIAL_MEDIA"],
    title: "editSocialMedia",
    element: <AdvertiserSocialMediaForm />,
    guard: AuthGuard,
  },

  {
    path: ROUTES["ADD_CLIENTS_FAX"],
    title: "addFax",
    element: <AdvertiserFaxForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["EDIT_CLIENTS_FAX"],
    title: "editFax",
    element: <AdvertiserFaxForm />,
    guard: AuthGuard,
  },

  {
    path: ROUTES["ADVERTISING_PROJECTS"],
    title: "advertisingProjects",
    element: <AdvertiserProjects />,
    guard: AuthGuard,
  },
  { path: ROUTES["ADD_ANALYTICS"], element: <AboutForm />, guard: AuthGuard },
  { path: ROUTES["EDIT_ANALYTICS"], element: <AboutForm />, guard: AuthGuard },
  {
    path: ROUTES["ADD_ADVERTISING_PROJECTS"],
    title: "addProject",
    element: <AddAdvertiserProjects />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["EDIT_ADVERTISING_PROJECTS"],
    title: "editProject",
    element: <AddAdvertiserProjects />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["VIEW_ADVERTISING_PROJECTS"],
    title: "viewProject",
    element: <ViewAdvertiserProjects />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADD_ADVERTISING_MEDIA_CHANNELS"],
    title: "addAdvertiserChannels",
    element: <AddAdvertiserMediaChannels />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADD_AUDIENCE_INTEREST"],
    title: "addAudienceInterest",
    element: <AddAudienceInterest />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["VIEW_ADVERTISING_CLIENTS"],
    title: "viewAdvertiserClients",
    element: <ViewAdvertiserClients />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["OFFERS"],
    title: "offers",
    element: <Offers />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["VIEW_OFFER"],
    title: "viewOffer",
    element: <ViewOffer />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["TASKS"],
    title: "tasks",
    element: <Tasks />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["VIEW_TASKS"],
    title: "viewTask",
    element: <ViewTask />,
    guard: AuthGuard,
  },

  {
    path: ROUTES["ADVERTISER_OFFERS"],
    title: "advertiserOffers",
    element: <AdvertiserOffers />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_VIEW_OFFER"],
    title: "advertiserViewOffer",
    element: <ViewOffer />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_TASKS"],
    title: "advertiserTasks",
    element: <AdvertiserTasks />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_VIEW_TASKS"],
    title: "advertiserViewTask",
    element: <ViewTask />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_PUBLISHING_OFFERS"],
    title: "publishingOffers",
    element: <PublishingOffers />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_NEW_PUBLISHING_OFFERS"],
    title: "addPublishingOffers",
    element: <NewPublishingOffers />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_ADD_CUSTOM_OFFER"],
    title: "addCustomOffer",
    element: <AddCustomOffer />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_ADVERTISEMENT_CONTENT"],
    title: "advertisementContent",
    element: <AdvertisementContent />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_ADD_ADVERTISEMENT_CONTENT"],
    title: "addAdvertisementContent",
    element: <AdvertisementNewContentForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_EDIT_ADVERTISEMENT_CONTENT"],
    title: "editAdvertisementContent",
    element: <AdvertisementEditContentForm />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_VIEW_ADVERTISEMENT_CONTENT"],
    title: "viewAdvertisementContent",
    element: <AdvertisementContentView />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_CAMPAIGNS"],
    title: "campaigns",
    element: <Campaigns />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_ADD_CAMPAIGNS"],
    title: "addCampaigns",
    element: <AddCampaigns />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_PUBLICATIONS"],
    title: "advertiserPublications",
    element: <AdvertiserPublications />,
    guard: AuthGuard,
  },
  {
    path: ROUTES["ADVERTISER_NEW_PUBLICATIONS"],
    title: "advertiserNewPublication",
    element: <AdvertiserNewPublication />,
    guard: AuthGuard,
  },
];
