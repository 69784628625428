import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Container,
  IconButton,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  CardProps,
  CardContentProps,
  Divider,
} from "@mui/material";
import {
  AMApplicationLogo,
  AMNavLink,
  AMSelectLanguages,
  AMBreadcrumbs,
  AMCustomSelect,
  AMNotification,
} from "components/common";
import { AMBadgeNotification } from "components/icon";
import { AMPopoverCard, AMMenuDrawer } from "components/widgets";
import menuData from "data/front-end-modals/menu.json";
import { myAdvertmingo } from "images";
import {
  PersonOutlineOutlined,
  Settings,
  Menu,
  Language,
  ArrowDropDown,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import ROUTES from "utils/CommonRouter";
import {
  notificationList,
  getNotificationCount,
  fetchUserProfile,
} from "api/api";
import { useContextCommonDataModel } from "context/CommonContext";
import { uiConfig } from "designsystem";
import useApi from "middleware/useApi";
import { SUCCESS_STATE, WEB } from "utils/CommonText";
import LeftMenu from "../UserLayout/UserMenu/Menu";
import * as colors from "designsystem/Palette";

interface HeaderProps
  extends Omit<CardProps<"div", {}>, "classes">,
    CardContentProps {
  leftMenu?: boolean;
  boxShadow?: string;
}

interface NotificationItem {
  id: number;
  orderCount: number;
  totalCount: number;
  displayItemTitle: string;
  detailItemText: string;
  timestamp: string;
}

interface NotificationCategory {
  totalCount: number;
  items: NotificationItem[];
}

interface Notifications {
  high: NotificationCategory;
  medium: NotificationCategory;
  low: NotificationCategory;
}

const MainHeader = ({ leftMenu, boxShadow, ...rest }: HeaderProps) => {
  // FOR Translation
  const { i18n, t } = useTranslation("menu");
  const navigate = useNavigate();
  const routes: Record<string, string> = ROUTES;

  // FOR BREADCRUMBS
  const { pathname } = useLocation();
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);
  const [toggleNotification, setToggleNotification] = useState(false);
  const [toggleLanguages, setToggleLanguages] = useState(false);
  const { authenticated } = useContextCommonDataModel();
  const [notificationItems, setNotificationItems] = useState<Notifications>({
    high: { totalCount: 0, items: [] },
    medium: { totalCount: 0, items: [] },
    low: { totalCount: 0, items: [] },
  });
  const [notificationCountShow, setNotificationCountShow] = useState(0);

  const doUserNotificationCount = useApi();
  const doUserNotification = useApi();
  const doUserFetchProfile = useApi();

  const notificationCountRef = useRef<() => void>();

  // TOGGLE NOTIFICATION
  const togglenotificationPopover = () => {
    if (!toggleNotification === true) {
      notification();
    }
    setToggleNotification(!toggleNotification);
  };

  // TOGGLE LANGUAGES
  const toggleLanguagesPopover = () => {
    setToggleLanguages(!toggleLanguages);
  };

  const requestData = (requestType: number) => {
    let requestTypeName = "";

    if (requestType === 1) {
      requestTypeName = "INIT_ANONYMOUS_LOAD_REQ";
    } else if (requestType === 2) {
      if (authenticated) {
        requestTypeName = "USER_NOTIFICATION_REQ";
      } else {
        requestTypeName = "ANONYMOUS_NOTIFICATION_REQ";
      }
    }

    const commonData = {
      correlationId: "124323sssf999",
      requestType: requestTypeName,
      feReqTimestamp: "dd/MM/yyyy HH:mm:ss",
      cliType: WEB,
    };

    return { commonData };
  };

  const shouldShowBreadcrumbs =
    pathname !== routes["INDEX_ROUTE"] &&
    pathname !== routes["CREATE_ACCOUNT_PRE_VERIFICATION"] &&
    pathname !== routes["CREATE_ACCOUNT_VERIFICATION_SUCCESS"];

  notificationCountRef.current = () => {
    const data = requestData(1);
    if (authenticated) {
      doUserFetchProfile.request({ apiFunction: fetchUserProfile });
    } else {
      doUserNotificationCount.request(
        { apiFunction: () => getNotificationCount(data) },
        false
      );
    }
  };

  const notification = () => {
    const data = requestData(2);
    doUserNotification.request(
      { apiFunction: () => notificationList(data) },
      false
    );
  };

  // DEFAULT PAGE LOADING FUNCTIONS
  useEffect(() => {
    setOpen(false);
    setShowBreadcrumbs(shouldShowBreadcrumbs);
  }, [pathname, routes, shouldShowBreadcrumbs]);

  useEffect(() => {
    notificationCountRef.current?.();
  }, [authenticated]);

  useEffect(() => {
    const { data, loading } = doUserNotificationCount;
    if (!loading && data && data?.status === SUCCESS_STATE) {
      setNotificationCountShow(data.notifications.totalCount);
    }
  }, [doUserNotificationCount, doUserNotificationCount.loading]);

  useEffect(() => {
    const { data, loading } = doUserNotification;

    if (!loading && data && data?.status === SUCCESS_STATE) {
      setNotificationItems(data.notifications.items);
    }
  }, [doUserNotification, doUserNotification.loading]);

  useEffect(() => {
    const { data, loading } = doUserFetchProfile;
    if (!loading && data && data?.status === SUCCESS_STATE) {
      setNotificationCountShow(data.notifications.totalCount);
    }
  }, [doUserFetchProfile, doUserFetchProfile.loading]);

  // FOR ADDING STYLE
  const theme = useTheme();

  // FOR DRAWER
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // FOR CLOSE POPOVER
  const closePopover = () => {
    setToggleLanguages(!toggleLanguages);
  };

  return (
    <AppBar position="fixed" elevation={0}>
      {/* CONTAINER SECTION STARTS HERE */}
      <Container
        maxWidth="xl"
        sx={{
          zIndex: "5",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            [theme.breakpoints.down("lg")]: {
              gridTemplateColumns: "21% auto 21%!important",
            },

            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "auto 20%!important",
            },
            [theme.breakpoints.down("sm")]: {
              gridTemplateColumns: "50% 50%!important",
            },
          }}
        >
          {/* LOGO SECTION STARTS HERE */}
          <AMApplicationLogo />
          {/* LOGO SECTION ENDS HERE */}

          {/* WEB VIEW MENU SECTION STARTS HERE */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow="1"
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            }}
          >
            {menuData
              .filter((menu) => menu.isHeader)
              .map((menu) => (
                <AMNavLink
                  key={menu.id}
                  menu={t(menu.menu)}
                  to={routes[menu.route]}
                  customKey={menu.id}
                  alignTxt="center"
                  route={routes[menu.route]}
                  icon={menu.icon}
                />
              ))}
          </Box>
          {/* WEB VIEW MENU SECTION ENDS HERE */}

          {/* RIGHT MENU SECTION STARTS HERE */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={"10px"}
            sx={{
              [theme.breakpoints.down("md")]: {
                flexDirection: "row-reverse",
                justifyContent: "flex-start",
              },
              [theme.breakpoints.up("sm")]: {
                gap: "10px",
              },
            }}
          >
            {/* MOBILE VIEW MENU DRAWER SECTION STARTS HERE */}
            <AMMenuDrawer
              closeclickevent={handleDrawerClose}
              clickevent={handleDrawerOpen}
              opendrawer={open}
              value={
                <Menu
                  sx={{
                    color: "customColor.menuIconColor",
                    cursor: "pointer",
                    display: "none",
                    [theme.breakpoints.down("md")]: {
                      display: "block",
                    },
                  }}
                />
              }
            >
              <Box
                display=""
                flexDirection="column"
                className="scroller"
                maxHeight="calc(100vh - 100px)"
              >
                <Card
                  elevation={0}
                  sx={{
                    display: "flex",
                    bgcolor: "transparent!important",
                    flexDirection: "column",
                  }}
                >
                  {authenticated && (
                    <section>
                      <LeftMenu isOpenMenu={true} customclass="mobilemenu" />
                      <Divider sx={{ marginBottom: "20px" }} />
                    </section>
                  )}
                  {/* MOBILE VIEW MENU SECTION STARTS HERE */}
                  {menuData
                    .filter((menu) => menu.isHeader && !menu.isMobile)
                    .map((menu) => (
                      <AMNavLink
                        key={menu.id}
                        menu={t(menu.menu)}
                        to={routes[menu.route]}
                        customKey={menu.id}
                        alignTxt="center"
                        route={routes[menu.route]}
                        icon={menu.icon}
                      />
                    ))}
                  {/* MOBILE VIEW MENU SECTION ENDS HERE */}

                  {/* MOBILE VIEW NODIFICATION SECTION STARTS HERE */}
                  <AMCustomSelect
                    TogglePopover={() => {
                      togglenotificationPopover();
                    }}
                    openTogglePopover={toggleNotification}
                    value={
                      <CardContent
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          padding: "0!important",
                          marginLeft: "20px",
                          bgcolor: "transparent!important",
                          color: "common.black",
                        }}
                      >
                        <Typography variant="body2">
                          {t("notification", { ns: "notification" })}
                        </Typography>
                        <AMBadgeNotification
                          totalCount={notificationCountShow || "0"}
                          smallBadge={true}
                        />
                      </CardContent>
                    }
                  ></AMCustomSelect>
                  {/* MOBILE VIEW NODIFICATION SECTION ENDS HERE */}
                </Card>
                <Card
                  elevation={0}
                  sx={{
                    bgcolor: "transparent!important",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "30px",
                  }}
                >
                  {authenticated ? (
                    <AMNavLink menu={t("signOut")} to={routes["SIGN_OUT"]} />
                  ) : (
                    <AMNavLink menu={t("signin")} to={routes["SIGN_IN"]} />
                  )}
                  <CardActions
                    sx={{ padding: "0px!important", marginY: "-4px" }}
                  >
                    <AMNavLink
                      to={routes["MY_ADVERT_ROUTE"]}
                      icon={myAdvertmingo}
                      route={routes["MY_ADVERT_ROUTE"]}
                    />
                  </CardActions>
                  {/* MOBILE VIEW LANGUAGE STARTS HERE */}
                  <CardContent
                    sx={{
                      padding: "0!important",
                      marginBottom: "30px",
                    }}
                  >
                    <AMCustomSelect
                      TogglePopover={toggleLanguagesPopover}
                      openTogglePopover={toggleLanguages}
                      value={
                        <CardActions
                          sx={{ padding: "0px!important", marginLeft: "12px" }}
                        >
                          <Language
                            sx={{
                              color: "primary.light",
                              width: "18px",
                              marginRight: uiConfig.AM5,
                              marginLeft: uiConfig.AM5,
                            }}
                            className="globeimg"
                          />
                          <Typography
                            variant="body2"
                            sx={{ marginLeft: "0px!important" }}
                            className={`selected-lngcode ${
                              i18n.language ? "active" : ""
                            }`}
                          >
                            {i18n.language?.split("-")[0]}
                          </Typography>
                          <ArrowDropDown
                            sx={{
                              color: "secondary.light",
                              margin: "0 -7px 0px -2px!important",
                            }}
                            className="dropdown-icon"
                          />
                        </CardActions>
                      }
                    >
                      <AMPopoverCard
                        title={t("languages", { ns: "common" })}
                        icon={
                          <Language
                            sx={{
                              color: "primary.light",
                              width: "16px",
                              marginRight: uiConfig.AM5,
                              marginLeft: uiConfig.AM10,
                              display: "none",
                              [theme.breakpoints.down("md")]: {
                                display: "inline-block",
                              },
                            }}
                            className="globeimg"
                          />
                        }
                        closeTogglePopover={toggleLanguagesPopover}
                      >
                        {/* SCROLLER SECTION STARTS HERE */}
                        <AMSelectLanguages closePopover={closePopover} />
                        {/* SCROLLER SECTION ENDS HERE */}
                      </AMPopoverCard>
                    </AMCustomSelect>
                  </CardContent>
                  {/* MOBILE VIEW LANGUAGE ENDS HERE */}
                </Card>
              </Box>
            </AMMenuDrawer>
            {/* MOBILE VIEW MENU DRAWER SECTION ENDS HERE */}
            {/* LANGUAGES SECTION STARTS HERE */}
            <Card
              elevation={0}
              sx={{
                padding: "0!important",
                display: "block",
                overflow: "inherit!important",
                [theme.breakpoints.down("md")]: {
                  display: "none",
                },
              }}
            >
              <AMCustomSelect
                TogglePopover={toggleLanguagesPopover}
                openTogglePopover={toggleLanguages}
                value={
                  <CardActions
                    sx={{
                      padding: "0px!important",
                      lineHeight: "0px",
                    }}
                  >
                    <Language
                      sx={{
                        color: "primary.light",
                        width: "18px",
                        marginRight: uiConfig.AM5,
                        height: "auto",
                      }}
                      className="globeimg"
                    />
                    <Typography
                      variant="body2"
                      className={`selected-lngcode ${
                        i18n.language ? "active" : ""
                      }`}
                    >
                      {i18n.language?.split("-")[0]}
                    </Typography>
                    <ArrowDropDown
                      sx={{
                        color: "secondary.light",
                        margin: "0 -7px 0px -2px!important",
                        height: "auto",
                      }}
                      className="dropdown-icon"
                    />
                  </CardActions>
                }
              >
                <AMPopoverCard
                  title={t("languages", { ns: "common" })}
                  icon={
                    <Language
                      sx={{
                        color: "primary.light",
                        width: "18px",
                        marginRight: uiConfig.AM5,
                        marginLeft: uiConfig.AM10,
                        display: "none",
                        [theme.breakpoints.down("md")]: {
                          display: "inline-block",
                        },
                      }}
                      className="globeimg"
                    />
                  }
                  closeTogglePopover={toggleLanguagesPopover}
                >
                  {/* SCROLLER SECTION STARTS HERE */}
                  <AMSelectLanguages closePopover={closePopover} />
                  {/* SCROLLER SECTION ENDS HERE */}
                </AMPopoverCard>
              </AMCustomSelect>
            </Card>

            {/* LANGUAGES SECTION ENDS HERE */}

            {/* WEB VIEW NODIFICATION SECTION STARTS HERE */}
            <AMCustomSelect
              TogglePopover={() => {
                togglenotificationPopover();
              }}
              openTogglePopover={toggleNotification}
              value={
                <AMBadgeNotification
                  totalCount={notificationCountShow || "0"}
                />
              }
            >
              <AMPopoverCard
                title={t("notification", { ns: "notification" })}
                icon={
                  <AMBadgeNotification
                    totalCount={notificationCountShow || "0"}
                    style={{
                      display: "none",
                      [theme.breakpoints.down("md")]: {
                        display: "block",
                      },
                    }}
                  />
                }
                closeTogglePopover={() => {
                  togglenotificationPopover();
                }}
                btnFooter={
                  <Button
                    disableFocusRipple
                    disableTouchRipple
                    className="notifyBtn"
                    aria-label={"Notify Icon"}
                    aria-labelledby={"Notify Icon"}
                  >
                    {t("manage", { ns: "notification" })}
                    <Settings
                      sx={{
                        marginLeft: "10px",
                        width: "24px",
                        color: "#00000040",
                      }}
                    />
                  </Button>
                }
              >
                {/* SCROLLER SECTION STARTS HERE */}
                <AMNotification
                  notifications={notificationItems}
                  isLoading={doUserNotification?.loading}
                />
                {/* SCROLLER SECTION ENDS HERE */}
              </AMPopoverCard>
            </AMCustomSelect>
            {/* WEB VIEW NODIFICATION SECTION ENDS HERE */}

            {/* WEB VIEW SIGNIN AND SIGNOUT SECTION STARTS HERE */}
            {authenticated ? (
              <IconButton
                onClick={() => {
                  navigate(routes["SIGN_OUT"]);
                }}
                disableFocusRipple
                disableTouchRipple
                sx={{
                  backgroundColor: "transparent!important",
                  padding: 0,
                  borderRadius: "0",
                  [theme.breakpoints.down("md")]: {
                    borderBottom: "3px solid",
                    borderBottomColor:
                      pathname === routes["SIGN_OUT"]
                        ? "primary.main"
                        : "transparent",
                  },
                  [theme.breakpoints.up("lg")]: {
                    "& .MuiTypography-root": {
                      lineHeight: "16px",
                      borderBottom: "3px solid",
                      borderBottomColor:
                        pathname === routes["SIGN_OUT"]
                          ? "primary.main"
                          : "transparent",
                    },
                    "&:hover .MuiTypography-root": {
                      borderBottom: "3px solid",
                      borderBottomColor: "primary.main",
                    },
                  },
                }}
              >
                <PersonOutlineOutlined
                  width={"28px"}
                  height={"28px"}
                  sx={{
                    color: "primary.light",
                    marginRight: "4px",
                    [theme.breakpoints.down("md")]: {
                      marginRight: "0px",
                    },
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      display: "none",
                    },
                  }}
                >
                  {t("signOut")}
                </Typography>
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  navigate(routes["SIGN_IN"]);
                }}
                disableFocusRipple
                disableTouchRipple
                sx={{
                  backgroundColor: "transparent!important",
                  padding: 0,
                  borderRadius: "0",
                  [theme.breakpoints.down("md")]: {
                    borderBottom: "3px solid",
                    borderBottomColor:
                      pathname === routes["SIGN_IN"]
                        ? "primary.main"
                        : "transparent",
                  },
                  [theme.breakpoints.up("lg")]: {
                    "& .MuiTypography-root": {
                      lineHeight: "16px",
                      borderBottom: "3px solid",
                      borderBottomColor:
                        pathname === routes["SIGN_IN"]
                          ? "primary.main"
                          : "transparent",
                    },
                    "&:hover .MuiTypography-root": {
                      borderBottom: "3px solid",
                      borderBottomColor: "primary.main",
                    },
                  },
                }}
              >
                <PersonOutlineOutlined
                  sx={{
                    color: "primary.light",
                    marginRight: "4px",
                    [theme.breakpoints.down("md")]: {
                      marginRight: "0px",
                    },
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      display: "none",
                    },
                  }}
                >
                  {t("signin")}
                </Typography>
              </IconButton>
            )}
            {/* WEB VIEW SIGNOUT AND SIGNIN SECTION ENDS HERE */}
          </Box>

          {/* RIGHT MENU SECTION ENDS HERE */}
        </Toolbar>
      </Container>
      {/* CONTAINER SECTION ENDS  HERE */}
      {leftMenu && (
        <Box bgcolor={colors.menuBgColor}>
          <Container
            maxWidth="xl"
            sx={{
              zIndex: "5",
            }}
          >
            <LeftMenu isOpenMenu={true} customclass="horizontalmenu" />
          </Container>
        </Box>
      )}

      {/* ALL PAGE BREADCRUMBS SECTION STARTS HERE */}
      {showBreadcrumbs && <AMBreadcrumbs boxShadow={boxShadow} />}
      {/* ALL PAGE BREADCRUMBS SECTION ENDS HERE */}
    </AppBar>
  );
};

export default MainHeader;
