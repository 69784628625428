export const ADVERTISER = "advertiser";
export const CONTACT = "contact";
export const MEDIACHANNEL = "media-channel";
export const ERROR = "error";
export const WARNING = "warning";
export const INFO = "info";
export const SUCCESS = "success";
export const MARKETINGMAIL = "marketing@tuneitcon.com";
export const SALESMAIL = "sales@tuneitcon.com";
export const SUPPORTMAIL = "support@tuneitcon.com";
export const TUNEITCONTEXT = "www.tuneitcon.com link";
export const TUNEITCONLINK = "https://www.tuneitcon.com";
export const REPORTERROR = "report error";
export const REPORTFRAUD = "report fraud";
export const WEB = "web_cli";
export const SUCCESS_STATE = "SUCCESS";
export const ERROR_STATE = "ERROR";

export const ERROR_REASON_RT_EXPIRED = "RT_EXPIRED";
export const ERROR_REASON_RT_ERROR = "RT_ERROR";
export const ERROR_REASON_AT_EXPIRED = "AT_EXPIRED";
export const ERROR_REASON_AT_ERROR = "AT_ERROR";
export const ERROR_REASON_AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR";
export const ERROR_REASON_AUTH_UNKNOWN_ERROR = "AUTH_UNKNOWN_ERROR";

export const ACCESSTOKEN = "your-access-token";
export const REFRESHTOKEN = "your-refresh-token";
export const TESTINGMAIL = "user@advertmingo.com";
export const TESTINGPHONENUMBER = "+440000000000";

export const CONTACT_EMAIL_KEY = "email";
export const CONTACT_PHONE_KEY = "phone";
export const CONTACT_SOCMEDIA_KEY = "social_media";
export const CONTACT_ADDRESS_KEY = "address";
export const CONTACT_FAX_KEY = "fax";
export const BANK_KEY = "bank";
export const BANK_DETAILS_KEY = "bankDetails";
export const MENU_PAGE = "Pages";
export const FIELD_ERROR = "FIELD_ERROR";
export const ACCOUNT_DETAILS = "Account Details";
export const BANK_DETAILS = "Bank Details";
export const BENEFICIARY_DETAILS = "Beneficiary Details";
export const VIEW_ICON = "viewicon";
export const step1Keys = [
  "bankTypeApiName",
  "isPrimary",
  "alias",
  "accountName",
  "accountNumber",
  "sortCode",
  "iban",
  "swiftCode",
  "accountCurrency",
];
export const step2Keys = [
  "bankName",
  "bankBranchName",
  "bankAddressLine1",
  "bankAddressLine2",
  "bankAddressLine3",
  "postcode",
  "bankCity",
  "bankState",
  "bankCounty",
  "bankCountry",
  "bankPhoneNumber",
  "bankFaxNumber",
  "bankEmail",
  "bankConfirmEmail",
];
export const step3Keys = [
  "beneficiaryAddressLine1",
  "beneficiaryAddressLine2",
  "beneficiaryAddressLine3",
  "beneficiaryPostcode",
  "beneficiaryCity",
  "beneficiaryState",
  "beneficiaryCounty",
  "beneficiaryCountry",
  "beneficiaryPhoneNumber",
  "beneficiaryEmail",
  "beneficiaryConfirmEmail",
];
export const TABLE_DEFAULT_ROWS_PER_PAGE = 5;
export const PAYMENT_CURRENT_PAYMENT_TAB_KEY = "Current Payments";
export const PAYMENT_CREDITED_PAYMENT_TAB_KEY = "Request Credited Payments";
export const PAYMENT_PAYMENT_HISTORY_TAB_KEY = "Payment History";
export const PAYMENT_INVOICE_TAB_KEY = "Invoice";

export const SOCIAL_MEDIA_TAB_KEY = "Social Media";
export const GENERAL_TAB_KEY = "General";
export const REQUEST_PREFERRED_TAB_KEY = "REQUEST_PREFERRED_MC_USER_MC_TYPE";
export const MC_REQUEST_PREFERRED_TAB_KEY = "Requested Preferred Media Channel";
export const CUSTOM_MC_TAB_KEY = "CUSTOM_MC_USER_MC_TYPE";
export const ABOUT_FOLLOWS_TAB_KEY = "About Follows";
export const CUSTOM_MEDIA_CHANNEL_TAB_KEY = "Custom Media Channels";
export const ALL_MEDIA_CHANNEL_TAB_KEY = "All Media Channels";
export const ABOUT_ME_TAB_KEY = "About Me";
export const ABOUT_ORGANIZATION_TAB_KEY = "About My Organisation";
export const ABOUT_AUDIENCE_TAB_KEY = "About Audience";

export const ADVERTISER_PROJECT_TAB_KEY = "Project";
export const ADVERTISER_CHANNEL_TAB_KEY = "Advertising Channels";
export const ADVERTISER_AUDIENCE_TAB_KEY = "Audience/Interests";

export const ADVERTISER_CLIENT_TAB_KEY = "Clients";
export const ADVERTISER_CLIENT_CONTACT_TAB_KEY = "Client Contact";
export const ADVERTISER_CLIENT_PROJECT_TAB_KEY = "Client Project";

export const CONTACT_EMAILS_KEY = "emails";
export const CONTACT_PHONES_KEY = "phones";
export const CONTACT_SOCIAL_MEDIAS_KEY = "social_medias";
export const CONTACT_ADDRESSES_KEY = "addresses";
export const CONTACT_FAXES_KEY = "faxes";
export const CONTACT_EDIT_EMAILS_KEY = "editEmail";
export const CONTACT_EDIT_PHONES_KEY = "editPhone";
export const CONTACT_EDIT_SOCIAL_MEDIAS_KEY = "editSocialMedia";
export const CONTACT_EDIT_ADDRESSES_KEY = "editAddress";
export const CONTACT_EDIT_FAXES_KEY = "editFax";
export const CONTACT_ADD_EMAILS_KEY = "email";
export const CONTACT_ADD_PHONES_KEY = "phone";
export const CONTACT_ADD_SOCIAL_MEDIAS_KEY = "socialMedia";
export const CONTACT_ADD_ADDRESSES_KEY = "address";
export const CONTACT_ADD_FAXES_KEY = "fax";

export const EDIT_ICON = "editicon";
export const DELETE_ICON = "deleteicon";
export const ADD_ICON = "addicon";
export const PROFILE_NAME = "Elnora";
export const PROFILE_DESCRIPTION =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae sollicitudin massa. Maecenas non molestie tellus. Nulla sit amet cursus mi. Suspendisse sagittis turpis ante, at condimentum ante eleifend sit amet. Nulla facilisi. Quisque lectus lorem, consectetur sed elementum quis, facilisis sit amet metus. Ut gravida enim ut mi convallis, non ultrices neque euismod.";

export const INTERESTS = "INTEREST_ANALYTICS_TYPE";
export const LOCATIONS = "LOCATION_ANALYTICS_TYPE";
export const LANGUAGES = "LANGUAGE_ANALYTICS_TYPE";
export const EDUCATIONS = "EDUCATION_ANALYTICS_TYPE";
export const PROFESSIONS = "PROFESSION_ANALYTICS_TYPE";
export const SALARIES = "SALARY_ANALYTICS_TYPE";
export const WEALTHS = "WEALTH_ANALYTICS_TYPE";
export const HOBBIES = "HOBBY_ANALYTICS_TYPE";
export const MUSIC_PREFERENCES = "MUSIC_PREFERENCE_ANALYTICS_TYPE";
export const MOVIE_PREFERENCES = "MOVIE_PREFERENCE_ANALYTICS_TYPE";
export const AGES = "AGE_ANALYTICS_TYPE";
export const GENDERS = "GENDER_ANALYTICS_TYPE";

export const TRAVEL_HOLIDAY = "TRAVEL_HOLIDAY_ANALYTICS_TYPE";
export const RESTAURANT_DINNING = "RESTAURANT_DINNING_ANALYTICS_TYPE";
export const BAR_NIGHTLIFE = "BAR_NIGHTLIFE_ANALYTICS_TYPE";
export const FOOD_ANALYTICS = "FOOD_ANALYTICS_TYPE";
export const DRINK_BEVERAGE = "DRINK_BEVERAGE_ANALYTICS_TYPE";
export const STYLE_CLOTHING = "STYLE_CLOTHING_ANALYTICS_TYPE";
export const SPORT = "SPORT_ANALYTICS_TYPE";
export const CAR_AUTOMOBILE = "CAR_AUTOMOBILE_ANALYTICS_TYPE";

export const DATA_RETURN_TYPE_ALL = "all";
export const ADD_KEY = "add";
export const EDIT_KEY = "edit";

export const ADD = "Add ";
export const CATEGORY_KEY = "category";
export const VARIABLE_KEY = "variable";
export const SAVE_CONTINUE_BTN = "saveContinueBtn";
export const NEXT_BTN = "nextBtn";
export const PROJECT_ROUTE = "ADD_ADVERTISING_PROJECTS";
export const CLIENT_ROUTE = "ADVERTISING_CLIENTS";

export const ADV_SPECIAL_OFFER_TAB = "Special Offers";
export const ADV_CUSTOM_OFFER_TAB = "search for custom offers";

export const ADV_ALL_TAB = "All";
export const ADV_VERIFIED_TAB = "Verified";
export const ADV_VERIFICATION_PENDING_TAB = "Verification Pending";
export const ADV_REJECTED_TAB = "Rejected";
export const ADV_REQUIRED_TAB = "Clarification Required";

export const CHECK_FILE_UPLOAD = [
  "VIDEO_AD_CONTENT_FORMAT_TYPE",
  "IMAGE_AD_CONTENT_FORMAT_TYPE",
  "AUDIO_AD_CONTENT_FORMAT_TYPE",
  "DOCUMENT_AD_CONTENT_FORMAT_TYPE",
];
export const CHECK_TEXT_EDITOR = [
  "TEXT_AD_CONTENT_FORMAT_TYPE",
  "HTML_TEXT_AD_CONTENT_FORMAT_TYPE",
];
export const CHECK_WEB_LINK = ["WEB_URL_LINK_AD_CONTENT_FORMAT_TYPE"];
export const ADV_CONTENT_CAMPAIGNSTATUS = "ASSIGNED_CAMPAIGN_STATUS_TYPE";
export const ADV_CONTENT_VIDEO = "VIDEO_AD_CONTENT_FORMAT_TYPE";
export const ADV_CONTENT_IMAGE = "IMAGE_AD_CONTENT_FORMAT_TYPE";
export const ADV_CONTENT_AUDIO = "AUDIO_AD_CONTENT_FORMAT_TYPE";
export const ADV_CONTENT_DOCUMENT = "DOCUMENT_AD_CONTENT_FORMAT_TYPE";

export const ADV_CONTENT_TEXT = "TEXT_AD_CONTENT_FORMAT_TYPE";
export const ADV_CONTENT_HTML = "HTML_TEXT_AD_CONTENT_FORMAT_TYPE";
export const ADV_CONTENT_WEB_LINK = "WEB_URL_LINK_AD_CONTENT_FORMAT_TYPE";
export const ADV_HANDLE_VERIFY_LATER_BTN = "saveAndVerifyLater";
export const ADV_HANDLE_VERIFY_BTN = "submitForVerification"

