import React, { ReactNode } from "react";
import { IconButton, Popover } from "@mui/material";

interface AMPopoverProps {
  icon: ReactNode;
  children: ReactNode;
  isInfo?: boolean;
  classess?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

const AMPopover = ({
  icon,
  children,
  isInfo,
  classess,
  isOpen, // new prop to control open state
  onClose, // new prop to handle close from parent
}: Readonly<AMPopoverProps>) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) {
      onClose();
    }
  };

  const open = isOpen !== undefined ? isOpen : Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        className={`${isInfo ? "info-wrp" : ""} ${classess}`}
        onClick={handleClick}
        aria-describedby={id}
        sx={{
          paddingBlock: 0,
        }}
      >
        {icon}
      </IconButton>

      <Popover
        className={`popover-wrp ${classess}`}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </Popover>
    </div>
  );
};

export default AMPopover;
