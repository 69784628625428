import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const ButttonStyles = {
  root: {
    "&.common-button": {
      borderRadius: uiConfig.AM20,
      minWidth: uiConfig.AM90,
      "&.small-btn": {
        minWidth: uiConfig.AMAuto,
        padding: uiConfig.AMDrwopDownBtn,
        lineHeight: uiConfig.AMNormal,
        fontSize: textStyles.fontSize.ms13,
        fontFamily: textStyles.fontFamily.defaultFont,
        textTransform: uiConfig.AMNone,
        fontWeight: textStyles.fontWeight.strong,
      },
    },
    "&.status-button": {
      borderRadius: uiConfig.AM20,
      padding: uiConfig.AMBtnReducePadding,
      fontSize: textStyles.fontSize.small,
      border: `${uiConfig.AM0}!important`,
      whiteSpace: uiConfig.AMNowrap,
      cursor: uiConfig.AMDefault,
    },
    "& .MuiButton-endIcon": {
      display: `${uiConfig.AMNone} !important`,
    },
    "& .MuiLoadingButton-loadingIndicator": {
      position: uiConfig.AMInitial,
      marginLeft: uiConfig.AM10,
    },
    "&.dropdownbtn": {
      padding: `${uiConfig.AM0} ${uiConfig.AM5} !important`,
      minWidth: `${uiConfig.AMAuto} !important`,
      borderRadius: `${uiConfig.AM5} !important`,
      borderBottomLeftRadius: `${uiConfig.AM0} !important`,
      borderBottomRightRadius: `${uiConfig.AM0} !important`,
      backgroundColor: `${colors.transparent} !important`,
      "& .MuiTypography-root": {
        marginTop: uiConfig.AM0,
      },
    },
    "&.notifyBtn": {
      fontFamily: textStyles.fontFamily.defaultFont,
      padding: ` ${uiConfig.AM0} !important`,
      fontSize: textStyles.fontSize.base,
      textTransform: uiConfig.AMNone,
      fontWeight: textStyles.fontWeight.normal,
    },
  },
};
