import { textStyles } from "designsystem";
import * as colors from "designsystem/Palette";

export const IconButttonStyles = {
  root: {
    "&.info-wrp": {
      background: colors.secondaryDarkColor,
      ".MuiSvgIcon-root": {
        fill: colors.primaryDarkColor,
      },
    },
    "&.userCount": {
      backgroundColor: `${colors.primaryDarkColor} !important`,
      color: colors.whiteColor,
      fontSize: textStyles.fontSize.small,
      marginLeft: "10px",
      borderRadius: "50px",
    },
  },
};
