export const ROUTES: Record<string, string> = {
  INDEX_ROUTE: "/",
  ADVERTISER_ROUTE: "/client-detail/advertiser",
  MEDIA_CHANNEL_ROUTE: "/client-detail/media-channel",
  ABOUT_ROUTE: "/about",
  CONTACT_ROUTE: "/contact",
  MY_ADVERT_ROUTE: "/my-advertmingo",
  ADVERTISER_FAQ_ROUTE: "/client-detail/advertiser/faq",
  MEDIA_CHANNEL_FAQ_ROUTE: "/client-detail/media-channel/faq",
  CONTACT_FAQ_ROUTE: "/contact/faq",
  SIGN_IN: "/sign-in",
  ADVERTISER_SIGN_IN: "/client-detail/advertiser/sign-in",
  MEDIA_CHANNEL_SIGN_IN: "/client-detail/media-channel/sign-in",
  CREATE_ACCOUNT: "/create-account",
  CREATE_ACCOUNT_PRE_VERIFICATION: "/create-account-pre-verification",
  CREATE_ACCOUNT_VERIFICATION_SUCCESS: "/create-account-success",
  ERROR: "/error",
  SERVICES: "/services",
  EMPLOYMENT: "/employment",
  OURPOLICIES: "/our-policies",
  TERM_AND_CONDITIONS: "/term-and-conditions",
  BLOG: "/blog",
  QA: "/qa",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  SIGN_OUT: "/sign-out",
  REPORT_FRAUD_SUCCESS: "/report-fraud-success",
  REPORT_FRAUD: "/report-fraud",
  UNDER_CONSTRUCTION: "/under-construction",
  REPORT_ERROR: "/report-error",
  SUCCESS: "/success",
  CHART: "/chart",
  PROFILE: "/my-advertmingo/profile",
  CONTACTS: "/my-advertmingo/contacts",
  MENU_TWO: "/my-advertmingo/menu/2",
  MENU_THREE: "/my-advertmingo/menu/3",
  MENU_FOUR: "/my-advertmingo/menu/4",
  MENU_SIX: "/my-advertmingo/menu/6",
  SUBMENU_TWO: "/my-advertmingo/sub-menu/2",
  SUBMENU_THREE: "/my-advertmingo/sub-menu/3",
  SUBMENU_FOUR: "/my-advertmingo/sub-menu/4",
  SUBMENU_FIVE: "/my-advertmingo/sub-menu/5",
  SUBMENU_SIX: "/my-advertmingo/sub-menu/6",
  SUB_SUBMENU_ONE: "/my-advertmingo/sub-submenu/1",
  SUB_SUBMENU_TWO: "/my-advertmingo/sub-submenu/2",
  TABLE: "/my-advertmingo/table",
  TABLENEW: "/my-advertmingo/table2",
  TABLE_EDIT_RECORD: "/my-advertmingo/table/:id",
  TABLE_CREATE_RECORD: "/my-advertmingo/table/create-record",
  BASIC_COMPONENTS_ACCORDION: "/my-advertmingo/basic-components/accordion",
  BASIC_COMPONENTS_CHIP: "/my-advertmingo/basic-components/chip",
  BASIC_COMPONENTS_DRAWER: "/my-advertmingo/basic-components/drawer",
  BASIC_COMPONENTS_LIST: "/my-advertmingo/basic-components/list",
  BASIC_COMPONENTS_TABS: "/my-advertmingo/basic-components/tabs",
  BASIC_COMPONENTS_CARDS: "/my-advertmingo/basic-components/cards",
  BASIC_COMPONENTS_ALERT: "/my-advertmingo/basic-components/alert",
  BASIC_COMPONENTS_LOADER: "/my-advertmingo/basic-components/loader",
  BASIC_COMPONENTS_PROGRESS: "/my-advertmingo/basic-components/progress",
  BASIC_COMPONENTS_MAINTENANCE: "/my-advertmingo/basic-components/maintenance",
  BASIC_COMPONENTS_FORMFIELDS: "/my-advertmingo/basic-components/form-fields",
  BASIC_COMPONENTS_UNDER_CONSTRUCTION:
    "/my-advertmingo/basic-components/under-construction",

  CONTACT_EDIT_EMAIL: "/my-advertmingo/contacts/edit-email",
  CONTACT_ADD_EMAIL: "/my-advertmingo/contacts/create-email",
  CONTACT_EDIT_ADDRESS: "/my-advertmingo/contacts/edit-address",
  CONTACT_ADD_ADDRESS: "/my-advertmingo/contacts/create-address",
  CONTACT_EDIT_PHONE: "/my-advertmingo/contacts/edit-phone",
  CONTACT_ADD_PHONE: "/my-advertmingo/contacts/create-phone",
  CONTACT_EDIT_FAX: "/my-advertmingo/contacts/edit-fax",
  CONTACT_ADD_FAX: "/my-advertmingo/contacts/create-fax",
  CONTACT_EDIT_SOCIAL_MEDIA: "/my-advertmingo/contacts/edit-social-media",
  CONTACT_ADD_SOCIAL_MEDIA: "/my-advertmingo/contacts/create-social-media",

  BANK_DETAILS: "/my-advertmingo/bank-details",
  BANK_DETAILS_EDIT_BANK: "/my-advertmingo/bank-details/edit-bank",
  BANK_DETAILS_ADD_BANK: "/my-advertmingo/bank-details/create-bank",
  SECURITY: "/my-advertmingo/security",
  BILLING_PAYMENTS: "/my-advertmingo/billing-payments",
  BANK_DETAILS_VIEW: "/my-advertmingo/bank-details/view",
  PAYMENTS: "/my-advertmingo/payments",

  PAYMENTS_DETAILS: "/my-advertmingo/payments-details",
  ORDER_DETAILS: "/my-advertmingo/payments/order-details",
  PAYMENT_VIEW_INVOICE: "/my-advertmingo/payments/invoice-details",
  PAYMENT_VIEW_CONTENT_POST: "/my-advertmingo/payments/contentPost",
  MY_MEDIA_CHANNELS: "/my-advertmingo/my-media-channels",
  MEDIA_CHANNELS: "/my-advertmingo/media-channels",
  ADD_MEDIA_CHANNELS: "/my-advertmingo/media-channels/create",
  EDIT_MEDIA_CHANNELS: "/my-advertmingo/media-channels/edit",
  MEDIA_CHANNELS_ASSESSMENT: "/my-advertmingo/media-channels/assessment",
  EDIT_SOCIAL_MEDIA_CHANNELS:
    "/my-advertmingo/media-channels/edit-social-media-channels",
  ADD_ABOUT: "/my-advertmingo/media-channels/about/create",
  EDIT_ABOUT: "/my-advertmingo/media-channels/about/edit",
  ANALYTICS: "/my-advertmingo/analytics",
  TASKS: "/my-advertmingo/tasks",
  VIEW_TASKS: "/my-advertmingo/tasks/view-task",
  MEDIA_CHANNELSV2: "/my-advertmingo/media-channels-v2",
  ADD_MEDIA_CHANNELSV2: "/my-advertmingo/media-channels/create-v2",
  ADD_ANALYTICS: "/my-advertmingo/media-channels/analytics/create",
  EDIT_ANALYTICS: "/my-advertmingo/media-channels/analytics/edit",
  MY_ADVERTISER: "/my-advertmingo/advertiser",
  ADVERTISING_CLIENTS: "/my-advertmingo/advertiser-clients",
  ADD_ADVERTISING_CLIENTS: "/my-advertmingo/advertiser-clients/create",
  EDIT_ADVERTISING_CLIENTS: "/my-advertmingo/advertiser-clients/edit",
  ADD_CLIENTS_PHONE: "/my-advertmingo/advertiser-clients/phone/create",
  ADD_CLIENTS_EMAIL: "/my-advertmingo/advertiser-clients/email/create",
  ADD_CLIENTS_ADDRESS: "/my-advertmingo/advertiser-clients/address/create",
  EDIT_CLIENTS_PHONE: "/my-advertmingo/advertiser-clients/phone/edit",
  EDIT_CLIENTS_EMAIL: "/my-advertmingo/advertiser-clients/email/edit",
  EDIT_CLIENTS_ADDRESS: "/my-advertmingo/advertiser-clients/address/edit",
  ADVERTISING_PROJECTS: "/my-advertmingo/advertiser-projects",
  ADD_ADVERTISING_PROJECTS: "/my-advertmingo/advertiser-projects/create",
  EDIT_ADVERTISING_PROJECTS: "/my-advertmingo/advertiser-projects/edit",
  VIEW_ADVERTISING_PROJECTS: "/my-advertmingo/advertiser-projects/view",
  ADD_ADVERTISING_MEDIA_CHANNELS:
    "/my-advertmingo/advertiser-projects/create/create-media-channels",
  ADD_CLIENTS_SOCIAL_MEDIA:
    "/my-advertmingo/advertiser-clients/social-media/create",
  EDIT_CLIENTS_SOCIAL_MEDIA:
    "/my-advertmingo/advertiser-clients/social-media/edit",
  ADD_CLIENTS_FAX: "/my-advertmingo/advertiser-clients/fax/create",
  EDIT_CLIENTS_FAX: "/my-advertmingo/advertiser-clients/fax/edit",
  ADD_AUDIENCE_INTEREST:
    "/my-advertmingo/advertiser-projects/create/create-audience/interest",
  VIEW_ADVERTISING_CLIENTS: "/my-advertmingo/advertiser-clients/view",
  OFFERS: "/my-advertmingo/offers",
  VIEW_OFFER: "/my-advertmingo/offers/view-offer",

  ADVERTISER_OFFERS: "/my-advertmingo/advertiser/offers",
  ADVERTISER_VIEW_OFFER: "/my-advertmingo/advertiser/offers/view-offer",
  ADVERTISER_TASKS: "/my-advertmingo/advertiser/tasks",
  ADVERTISER_VIEW_TASKS: "/my-advertmingo/advertiser/tasks/view-task",
  ADVERTISER_PUBLISHING_OFFERS: "/my-advertmingo/advertiser/campaign-offers",
  ADVERTISER_NEW_PUBLISHING_OFFERS:
    "/my-advertmingo/advertiser/campaign-offers/create",
  ADVERTISER_ADD_CUSTOM_OFFER:
    "/my-advertmingo/advertiser/campaign-offers/create-custom-offer",
  ADVERTISER_ADVERTISEMENT_CONTENT:
    "/my-advertmingo/advertiser/advertisement-content",
  ADVERTISER_ADD_ADVERTISEMENT_CONTENT:
    "/my-advertmingo/advertiser/advertisement-content/create",
  ADVERTISER_EDIT_ADVERTISEMENT_CONTENT:
    "/my-advertmingo/advertiser/advertisement-content/edit",
  ADVERTISER_VIEW_ADVERTISEMENT_CONTENT:
    "/my-advertmingo/advertiser/advertisement-content/view",
  ADVERTISER_CAMPAIGNS: "/my-advertmingo/advertiser/campaigns",
  ADVERTISER_ADD_CAMPAIGNS: "/my-advertmingo/advertiser/campaigns/create",
  ADVERTISER_PUBLICATIONS: "/my-advertmingo/advertiser/advertiser-publications",
  ADVERTISER_NEW_PUBLICATIONS:
    "/my-advertmingo/advertiser/advertiser-publications/create",
};

export default ROUTES;
